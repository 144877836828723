
<div class="custom-select" [formGroup]="formGroup">
    <select  #selectEl class="select"
    [formControlName]="controlName"
    (change)="OnChange.emit($event);onSelectChange(selectEl)"
    (blur)="onSelectChange(selectEl)"
    *ngIf="!isObject(listOptionsFormated)">
        <option value="" hidden *ngIf="hasEmptyValue == false"></option>
        <option *ngFor="let option of listOptionsFormated" [value]="parseData(option.key)" >{{option.value}}</option>
    </select>

    <select  #selectEl class="select"
    [formControlName]="controlName"
    (change)="OnChange.emit($event);onSelectChange(selectEl)"
    *ngIf="isObject(listOptionsFormated)">
        <option value="" hidden *ngIf="hasEmptyValue == false"></option>
        <option *ngFor="let option of listOptionsFormated | keyvalue" [value]="parseData(option.key)" >{{option.value}}</option>
    </select>

    <label class="label" [for]="controlName" *ngIf="label !== ''"  [class.active]="shouldLabelFloat()">{{label}}{{isRequired ? '*': ''}}</label>
    <div class="underline" [ngClass]="{'selected': isFocused}">
        <div class="underline-selected"></div>
    </div>
    <input-error class="error" [id]="id" [control]="control"></input-error>
</div>
