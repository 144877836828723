<div class="content">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-12">
                    <div class="btn btn-black-noclick btn-sm mt-3 fw-normal">
                        <strong>Actividad</strong> ({{ currentMonth }}):
                    </div>
                    <button type="button" class="btn btn-black-click btn-sm mt-3 fw-normal" (click)="getActivationThisMonth(true)">
                        <strong>{{ activationThisMonth }}</strong> activaciones
                    </button>

                    <button type="button" class="btn btn-black-click btn-sm mt-3 me-3 fw-normal" (click)="getRequestThisMonth(true)">
                        <strong>{{ requestThisMonth }}</strong> solicitudes
                    </button>

                    <div class="btn btn-grey3-noclick btn-sm mt-3 fw-normal">
                        <strong>Alertas</strong>:
                    </div>

                    <button type="button" class="btn btn-grey3 btn-sm mt-3 fw-normal" (click)="getAlertThisMonth(true, 1); isAlertView = true">
                        <strong>{{ alertThisMonth }}</strong> pendientes
                    </button>

                    <button type="button" class="btn btn-grey3 btn-sm mt-3 fw-normal" (click)="getSuspendedThisMonth(1); isAlertView = true">
                        <strong>{{ suspendedThisMonth }}</strong> canceladas
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="resumen d-flex justify-content-between align-items-center">
                <div>
                    <digo-input
                        id="search"
                        [labelTemplate]="titleSearch"
                        (input)="searchClient($event.target.value)"
                        controlName="search"
                        isSearch="true"
                        type="text"
                        />
                    <ng-template #titleSearch>
                        <i-feather name="search" class="icon--fe" stroke-width="2.5"></i-feather>
                        <span class="search--cliente"> Buscar por persona o DNI</span>
                    </ng-template>
                </div>
                <!-- Filter -->
                <small class="text-muted">
                    <a href="#" data-bs-toggle="collapse" data-bs-target="#filtros" [attr.aria-expanded]="isCollapsed" aria-controls="filtros" title="Filtros avanzados">
                        <img src="./assets/img/filter.svg" class="img-fluid icon-filter" width="23">
                    </a>
                </small>
            </div>
        </div>
    </div>
</div>

<div class="" (window:scroll)="doSomethingOnWindowScroll($event)">
    <div class="row">
        <div class="col-lg-12">
            <div id="filtros" class="filters-collapse collapse" [ngbCollapse]="isCollapsed">

                <form [formGroup]="formGroup">
                    <div class="row">
                        <div class="col-lg-9 mt-4">
                            <digo-input
                                id="fullName"
                                label="Nombre y/o apellidos"
                                [formGroup]="formGroup"
                                controlName="full_name"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-3 mt-4">
                            <digo-input
                                id="document"
                                label="NIF/NIE/Pasaporte/CIF"
                                [formGroup]="formGroup"
                                controlName="document"
                                type="text"
                                />
                        </div>

                        <div class="col-lg-4 mt-4">
                            <digo-input
                                id="msisdn"
                                label="Nº teléfono móvil"
                                [formGroup]="formGroup"
                                controlName="msisdn_movil"
                                maxlength="9"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-4 mt-4">
                            <digo-datepicker
                                id="date_start"
                                label="Fecha de solicitud desde"
                                [formGroup]="formGroup"
                                controlName="min_date"
                                [maxDate]="maxDate"
                                (OnDateInput)="setMinDate($event)"
                                />
                        </div>

                        <div class="col-lg-4 mt-4">
                            <digo-datepicker
                                id="date_end"
                                label="Fecha de solicitud hasta"
                                [formGroup]="formGroup"
                                controlName="max_date"
                                [minDate]="minDate"
                                (OnDateInput)="setMaxDate($event)"
                                />
                        </div>

                        <div class="col-lg-4 mt-4">
                            <digo-select
                                id="status"
                                label="Estado"
                                [formGroup]="formGroup"
                                controlName="status"
                                [options]="superStatusOptions"
                                optionValue="value"
                                optionLabel="label"
                                [allowDefaultEmpty]="true"
                                />
                        </div>

                        <div class="col-lg-4 mt-4">
                            <digo-select
                                id="contract_type"
                                [label]="'Tipo de alta'"
                                [options]="listType"
                                [formGroup]="formGroup"
                                controlName="contract_type"
                                optionValue="id"
                                optionLabel="label"
                                [allowDefaultEmpty]="true"
                                />
                        </div>

                        <div class="col-lg-4 mt-4">
                            <digo-select
                                id="sort_by_date"
                                [label]="'Ordenar por fecha'"
                                [options]="listSortByDate"
                                [formGroup]="formGroup"
                                controlName="sort_by_date"
                                optionValue="id"
                                optionLabel="label"
                                [allowDefaultEmpty]="true"
                                />
                        </div>
                    </div>

                    <button class="btn btn-black-outline mb-1 btn-sm" type="button" (click)="advanceSearch()">Aplicar filtro</button>
                </form>

                <br>
            </div>
        </div>
    </div>
</div>

<div class="content" *ngIf="ordersFiltered.length > 0">
    <div class="row">
        <div class="col-lg-12">
            <!--- \\\\\\\Cliente -->
            <div class="card gedf-card mb-3" *ngFor="let order of ordersFiltered; let i = index">
                <div class="card-header">
                    <ul class="nav nav-pills card-header-pills topnav" id="customer-tab-{{ i }}" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link text-list active" data-bs-toggle="tab" href="#cliente-{{ i }}" role="tab" aria-controls="cliente" aria-selected="true">Cliente</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-list position-relative" data-bs-toggle="tab" role="tab" aria-controls="ob" aria-selected="false" href="#ob-{{ i }}">
                                <img src="./assets/img/comment.svg" class="img-fluid icon-list me-1" width="16">
                                <span class="mobile">Observaciones</span>
                                <span class="navbar-unread" *ngIf="order.annotation">1</span>
                            </a>
                        </li>
                    </ul>

                    <div class="pdv-list" *ngIf="order.created_at || order.user">
                        <!-- Fecha de solicitud -->
                        <span class="info-secundaria dir-text2" *ngIf="order.created_at">
                            Fecha de solicitud: {{ convertDate(order.created_at) }}
                            <br>
                        </span>
                        <!-- Usuario -->
                        <span class="badge badge-light text-dark badge-shape" *ngIf="order.user">
                            {{ order.user?.username}}
                        </span>
                    </div>
                </div>


                <div class="card-body bg-white">
                    <div class="tab-content" id="customer-tab-content-{{ i }}">
                        <div class="tab-pane fade show active" id="cliente-{{ i }}" role="tabpanel" aria-labelledby="">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list mb-0">
                                            <span class="text-list" *ngIf="order.customer_name != null && order.customer_name != ''">
                                                <b>{{ order.customer_name }} {{ order.customer_last_name }} {{ order.customer_second_last_name }}</b>
                                                <b *ngIf="order && order.customer_document && order.customer_document !== ''">
                                                    - {{ order?.customer_document_type?.description }}: {{ order.customer_document }}
                                                </b>
                                            <br><br>
                                            </span>
                                            <!-- Documento de identidad -->
                                            <!-- <span class="font-list" *ngIf="order && order.customer_document && order.customer_document !== ''">
                                                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                                {{ order?.customer_document_type?.digo_code }}: <b>{{ order.customer_document }}</b>
                                            </span> -->
                                            <!-- Tipo de alta -->
                                            <span class="font-list">
                                                <br>
                                                <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                Tipo de alta:
                                                <b *ngIf="order.type === 'broadband'">
                                                    Solo Fibra
                                                </b>
                                                <b *ngIf="order.type === 'convergent'">
                                                    {{ (order.broadband?.portability ? 'Portabilidad fibra' : 'Alta fibra') + ' + ' + (order.mobiles[0]?.portability ? 'Portabilidad móvil' : 'Alta móvil') + (order?.mobiles[0]?.rate?.name ? ' (' + order?.broadband?.rate?.name + '+' + order?.mobiles[0]?.rate?.name + ': ' + getConvergentPrice(order) + '€)' : '') }}
                                                </b>
                                                <b *ngIf="order.type !== 'convergent' && order.type !== 'broadband'">
                                                    {{ (order?.mobiles[0]?.portability ? 'Portabilidad móvil' : 'Alta móvil') + (order?.mobiles[0]?.rate?.name ? ' (' + order?.mobiles[0]?.rate?.name + ' ' + getRatePrice(order?.mobiles[0]?.rate) + '€)' : '') }}
                                                </b>
                                            </span>
                                            <!-- Estado fibra -->
                                            <span class="font-list" *ngIf="order.broadband && order.broadband.status_description && order.broadband.status_description != '' && order.super_status !== superStatus.draft">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Estado fibra: <b>{{ order.broadband.status_description }}</b>
                                            </span>
                                            <!-- MSISDN -->
                                            <span class="font-list" *ngIf="order.mobiles && order.mobiles.length > 0">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Línea móvil {{ order.mobiles[0].contract_type && order.mobiles[0].contract_type !== '' ? (order.mobiles[0].contract_type === 'prepaid' ? 'prepago' : 'pospago') : '' }}: <b>{{ (order.mobiles[0].new_msisdn && order.mobiles[0].new_msisdn !== '' ? order.mobiles[0].new_msisdn : 'Pendiente de asignar') }}</b>
                                            </span>
                                            <!-- Estado linea movil -->
                                            <span class="font-list"
                                                  *ngIf="order.mobiles && order.mobiles.length > 0 && order.mobiles[0] && order.mobiles[0].status_description && order.super_status !== superStatus.draft">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Estado línea móvil: <b>{{ order.mobiles[0].status_description }}</b>
                                            </span>
                                            <br *ngIf="order.mobiles && order.mobiles.length > 1">
                                            <br *ngIf="order.mobiles && order.mobiles.length > 1">
                                            <!-- Lista de moviles -->
                                            <span *ngFor="let mobile of order.mobiles">
                                                <div *ngIf="!mobile.principal">
                                                    <span class="font-list" [class.info-secundaria]="!mobile.principal" *ngIf="mobile.rate">
                                                        <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                        {{ mobile.portability ? 'Portabilidad ' : 'Alta ' }} línea adicional {{ mobile.contract_type && mobile.contract_type !== '' ? (mobile.contract_type === 'prepaid' ? 'prepago' : 'pospago') : '' }}:
                                                        <b>
                                                            {{ mobile.rate.name + ': ' + getRatePrice(mobile.rate) + '€ - ' + (mobile.new_msisdn ? mobile.new_msisdn : 'Pendiente de asignar') }}
                                                        </b>
                                                    </span>
                                                </div>
                                            </span>
                                        </h5>
                                    </div>
                                    <div  class="col-lg-4 d-flex justify-content-end">
                                        <span class="font-list text-list">
                                            <span class="{{ convertStatusClass(order.super_status) }}"></span>Estado: <b>{{ order.status_description }}</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="ob-{{ i }}" role="tabpanel" aria-labelledby="">
                            <div class="row">
                                <div class="col-xl-7">
                                    <digo-textarea #observations id="observations-{{ i }}" rows="3" [isDisabled]="savingAnnotation === order.order_id" label="Observaciones" [value]="order.annotation"></digo-textarea>
                                    <button type="button" class="btn btn-black btn-sm mt-3" (click)="updateAnnotation(order, observations.getValue())">
                                        <span *ngIf="savingAnnotation === order.order_id" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        {{ savingAnnotation === order.order_id ? 'Guardando...' : 'Guardar observaciones' }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="btn-toolbar justify-content-end mb-3 btn-list-top">
                            <div class="btn-group btn-list-bottom">
                                <a [routerLink]="['/republica-movil/order/', order.order_id]" [state]="{data: order}" class="btn btn-green btn-sm">
                                    Ver más detalles
                                </a>
                                <!-- <button class="btn btn-green btn-sm" (click)="continueProcess(order)" *ngIf="order.status < 0">
                                    Continuar tramitación
                                </button> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loaded" class="mt-2 mb-5">
    <h5  class="tar-name-preload text-center">Obteniendo ordenes...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner_rm.svg" width="30" alt="Cargando...">
    </div>
</div>

<div *ngIf="errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo</h5>
</div>

<div *ngIf="loaded && ordersFiltered.length == 0 && !errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">No se han encontrado ordenes.</h5>
</div>

<div class="mt-4 mb-4 d-flex justify-content-center" *ngIf="body.page < lastPage && loaded && !errorSearch">
    <button class="btn btn-black-outline d-block w-100 mb-1" (click)="loadMore()">Cargar más</button>
</div>
