<div class="content">
    <div class="row">
        <div class="col-lg-9">

        </div>
        <div class="col-lg-3">
            <div class="resumen d-flex justify-content-between align-items-center">
                <div>
                    <digo-input
                        id="search"
                        [labelTemplate]="titleSearch"
                        (input)="searchClient($event.target.value)"
                        isSearch="true"
                        type="text"
                    />
                    <ng-template #titleSearch>
                        <i-feather name="search" class="icon--fe" stroke-width="2.5"></i-feather>
                        <span class="search--cliente"> Buscar por nombre o DNI</span>
                    </ng-template>
                </div>
                <!-- Filter -->
                <small class="text-muted">
                    <a href="#" data-bs-toggle="collapse" data-bs-target="#filtros" [attr.aria-expanded]="isCollapsed" aria-controls="filtros" title="Filtros avanzados">
                        <img src="./assets/img/filter.svg" class="img-fluid icon-filter" width="23">
                    </a>
                </small>
            </div>
        </div>
    </div>
</div>

<div (window:scroll)="doSomethingOnWindowScroll($event)">
    <div class="row">
        <div class="col-lg-12">
            <div id="filtros" class="filters-collapse collapse" [ngbCollapse]="isCollapsed">

                <form [formGroup]="formGroup">
                    <div class="row">
                        <div class="col-lg-9 mt-3">
                            <digo-input
                                id="fullName"
                                label="Nombre y/o apellidos"
                                [formGroup]="formGroup"
                                controlName="full_name"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-3 mt-3">
                            <digo-input
                                id="document"
                                label="NIF/NIE/Pasaporte/CIF"
                                [formGroup]="formGroup"
                                controlName="document"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-4 mt-3">
                            <digo-input
                                id="msisdn"
                                label="Nº teléfono móvil"
                                [formGroup]="formGroup"
                                controlName="msisdn_movil"
                                maxlength="9"
                                type="text"
                            />
                        </div>

                        <div class="col-lg-4 mt-3">
                            <digo-datepicker
                                id="dateStart"
                                label="Fecha de solicitud desde"
                                [formGroup]="formGroup"
                                controlName="min_date"
                                [maxDate]="maxDate"
                                (OnDateInput)="setMinDate($event)"
                                />
                        </div>

                        <div class="col-lg-4 mt-3">
                            <digo-datepicker
                                id="dateStart"
                                label="Fecha de solicitud hasta"
                                [formGroup]="formGroup"
                                controlName="max_date"
                                [minDate]="minDate"
                                (OnDateInput)="setMaxDate($event)"
                            />
                        </div>

                        <div class="col-lg-4 mt-3">
                            <digo-select
                                id="status"
                                label="Estado"
                                [formGroup]="formGroup"
                                controlName="status"
                                [options]="statuses"
                                optionValue="id"
                                optionLabel="value"
                                [allowDefaultEmpty]="true"
                                />
                        </div>

                        <div class="col-lg-4 mt-3">
                            <digo-select
                                id="sort_by_date"
                                [label]="'Ordenar por fecha'"
                                [options]="listSortByDate"
                                [formGroup]="formGroup"
                                controlName="sort_by_date"
                                optionValue="id"
                                optionLabel="label"
                                [allowDefaultEmpty]="true"
                                />
                        </div>
                    </div>

                    <button class="btn btn-black-outline mb-1 btn-sm" type="button" (click)="advanceSearch()">Aplicar filtro</button>
                </form>

                <br>
            </div>
        </div>
    </div>
</div>

<div class="content" *ngIf="ordersFiltered.length > 0">
    <div class="row">
        <div class="col-lg-12">
            <!--- \\\\\\\Cliente -->
            <div class="card gedf-card mb-3" *ngFor="let order of ordersFiltered; let i = index">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <ul class="nav nav-pills card-header-pills topnav" id="customer-tab-{{ i }}" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link text-list active" data-bs-toggle="tab" href="#cliente-{{ i }}" role="tab" aria-controls="cliente" aria-selected="true">Cliente</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                            <div *ngIf="order.created_at">
                                <!-- Fecha de solicitud -->
                                <span class="info-secundaria dir-text2 d-inline-block">
                                    Fecha de solicitud: {{ convertDate(order.created_at) }} <br>
                                </span>
                                <!-- Usuario -->
                                <span class="badge badge-light text-dark badge-shape" *ngIf="order.user">
                                    {{ order.user?.username}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body bg-white">
                    <div class="tab-content" id="customer-tab-content-{{ i }}">
                        <div class="tab-pane fade show active" id="cliente-{{ i }}" role="tabpanel">
                            <div class=" mb-0">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list mb-0">
                                            <span class="text-list" *ngIf="order.first_name">
                                                <b>{{ order.first_name + ' ' + order.last_name + ' ' + order.second_last_name }}</b>
                                                <b *ngIf="order.document_type && order.document_number">
                                                    - {{ order?.document_type }}: {{ order.document_number }}
                                                </b>
                                                <br>
                                            </span>
                                            <!-- MSISDN -->
                                            <span class="font-list" *ngIf="order.msisdn && order.msisdn !== ''">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Nº de Simyo: <b>{{ order.msisdn }}</b>
                                            </span>
                                            <!-- Tipo de tarjeta -->
                                            <span class="font-list">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Tipo de tarjeta: <b>{{ order.is_esim == typeCard.esim ? 'eSIM' : 'SIM física' }}</b>
                                            </span>
                                            <!-- ICCID -->
                                            <span class="font-list" *ngIf="order.is_esim == typeCard.sim && order.new_iccid && order.new_iccid !== ''">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                ICCID nuevo: <b>{{ order.new_iccid }}</b>
                                            </span>
                                            <!-- Motivo del cambio -->
                                            <span class="font-list" *ngIf="order.reason && order.reason !== ''">
                                                <br>
                                                <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                Motivo del cambio: <b>{{ order.reason }}</b>
                                            </span>
                                        </h5>
                                    </div>
                                    <div  class="col-lg-4 d-flex justify-content-end">
                                        <span class="font-list text-list">
                                            <span class="{{ convertStatusClass(order.status) }}"></span>Estado: <b>{{ superStatusTranslate[order.status] }}</b>
                                        </span>
                                        <br>
                                        <span class="font-list text-list" *ngIf="order.status === superStatus.active || order.status === superStatus.in_process">
                                            <span class="{{ convertDocStatusClass(order.doc_status) }}"></span>Documentación: <b [title]="order.revision_date ? (order.revision_date | date: 'dd/MM/yyyy, HH:mm') : ''">{{ order.doc_status }}</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-toolbar justify-content-end mb-3">
                            <div class="btn-group">
                                <a [routerLink]="['/simyo/duplicados/', order.sim_swap_id]" class="btn btn-simyo-o2 btn-sm">
                                    Ver más detalles
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loaded" class="mt-2 mb-5">
    <h5  class="tar-name-preload text-center">
        Obteniendo ordenes...
    </h5>
    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
    </div>
    <br>
</div>

<div *ngIf="errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">
        Se ha producido un error, inténtalo de nuevo
    </h5>
</div>

<div *ngIf="loaded && ordersFiltered.length == 0 && !errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">
        No se han encontrado ordenes.
    </h5>
</div>

<div class="mt-4 mb-4 d-flex justify-content-center" *ngIf="body.page < lastPage && loaded && !errorSearch">
    <button class="btn btn-black-outline d-block w-100 mb-1" (click)="loadMore()">
        Cargar más
    </button>
    <br>
</div>
