import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from '../../baseComponent';

@Component({
  selector: 'digo-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent extends BaseComponent implements OnChanges {

    @Input() options = []
    @Input() optionValue = 'value'
    @Input() optionLabel = 'value'
    @Input() allowDefaultEmpty: boolean = false; //if allowDefaultEmpty is true, label will always be floating

    @Input() customeMessageError: string;
    @Input() placeholder: string;

    @Output() OnChange = new EventEmitter();


    listOptionsFormated = [];
    hasEmptyValue = false;

    constructor(formBuilder: FormBuilder, private cdRef: ChangeDetectorRef) {
        super(formBuilder);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.options?.currentValue) {
            let options = changes.options.currentValue;
            if(Array.isArray(options)) {
                this.hasEmptyValue = options.findIndex(o => o[this.optionValue] == '') >= 0;
                this.listOptionsFormated = options.map( o => {
                    return {
                        key: o[this.optionValue],
                        value: o[this.optionLabel]
                    }
                });
            } else if(typeof options === 'object' && !Array.isArray(options) && options !== null) {
                this.hasEmptyValue = options.hasOwnProperty("");
                this.listOptionsFormated = options;
            }
        }
        if(this.formGroup && this.controlName) {
            this.control = this.getControl(this.controlName);
            this.isEmpty = this.checkIsEmpty(this.control?.value);
            this.control.valueChanges.subscribe(value => {
            this.isEmpty = this.checkIsEmpty(value);
            });

            if(this.isDisabled) {
                this.control?.disable();
            } else {
                this.control?.enable();
            }
            this.isEmpty = this.checkIsEmpty(this.control.value) ;
            this.control.valueChanges.subscribe(data => {
                this.isEmpty = this.checkIsEmpty(data);
            })
        }

        if(changes.isDisabled) {
            if(changes.isDisabled.currentValue) {
                this.control?.disable();
            } else {
                this.control?.enable();
            }
        }

        if(changes.value) {
            this.control?.setValue(changes.value.currentValue);
        }
    }

    onSelectChange(selectElement: HTMLSelectElement): void {
        const selectedOption = selectElement.options[selectElement.selectedIndex];
        const visibleText = selectedOption?.textContent?.trim() || '';
        this.isEmpty = visibleText === '';
        this.cdRef.detectChanges();
    }

    shouldLabelFloat(): boolean {
        return this.allowDefaultEmpty || !this.isEmpty;
    }

    checkIsEmpty(data) {
        return data === undefined || data === null || data === '';
    }

    isObject(value: any): boolean {
        return value !== null && typeof value === 'object' && !Array.isArray(value);
    }

    parseData(data) {
        if(this.isJSONString(data)) {
            return JSON.parse(data);
        }
        return data
    }

    isJSONString(value: string): boolean {
        try {
          JSON.parse(value);  // Intenta convertir el string a objeto
          return true;        // Si no lanza error, entonces es un JSON válido
        } catch (e) {
          return false;       // Si lanza un error, entonces no es un JSON
        }
      }
}
