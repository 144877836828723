<div *ngIf="!loaded && !orderNotFound" class="mt-5 mb-5">
    <h5  class="tar-name-preload text-center">Obteniendo orden...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<div class="container mt-3" *ngIf="orderNotFound">
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4 text-center" >
                Lo sentimos, el pedido que está buscando no existe
            </h5>
            <div class="d-flex justify-content-center">
                <button (click)="goBack()" class="btn btn-simyo-o2 btn-sm mt-2">
                    Volver al listado
                </button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="loaded && simSwapOrder">
    <div class="container content mb-2">
        <div class="row">
            <div class="col-md-12">
                <h5 class="title-step mt-4 text-center" >
                    Resumen de la activación
                </h5>
            </div>
        </div>
    </div>
    <!--------- Resumen de la tramitación ------------->
    <!-------------------------------------------------->

    <div class="container firma mt-4 mb-5">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2">
                                            <b>Cliente</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">
                                <div class="row mt-4">
                                    <div class="col-lg-9">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list ms-3">
                                            <a class="text-list">
                                                <b>{{ simSwapOrder.first_name + ' ' + simSwapOrder.last_name + ' ' + simSwapOrder.second_last_name }}</b>
                                            </a>
                                            <br><br>
                                            <!-- Documento de identidad -->
                                            <span class="font-list">
                                                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                                {{ simSwapOrder.document_type }}: <b>{{ simSwapOrder.document_number }}</b>
                                            </span>
                                            <br>
                                            <!-- Fecha de solicitud -->
                                            <span class="font-list" *ngIf="simSwapOrder.created_at && simSwapOrder.created_at !== ''">
                                                <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                Fecha de solicitud: <b>{{ simSwapOrder.created_at | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                            </span>
                                            <br>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">

                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ms-3">
                                            <img src="./assets/img/file-text-negro.svg" class="img-fluid ico-title me-1" width="13">
                                            <b>Resumen de la tramitación</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item cuerpo-pack-2">
                                                <!-- RESUMEN MOVIL PACK -->
                                                <h5  class="tar-name-7 mt-2 ms-3">
                                                    <b>Duplicado</b>
                                                </h5>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <span class="sidebar-info4 simyo">Conceptos</span>
                                                    <small class="text-muted"></small>
                                                </div>
                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- MSISDN -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                        Nº de Simyo: <b>{{ simSwapOrder.msisdn }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- Tipo de tarjeta -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                        Tipo de tarjeta: <b>{{ simSwapOrder.is_esim == typeCard.esim ? 'eSIM' : 'SIM física' }}</b>
                                                        <ng-container *ngIf="simSwapOrder.status === superStatus.active">
                                                            <span *ngIf="simSwapOrder.is_esim == typeCard.esim && simSwapOrder.show_email" class="px-2 mx-2 custom-info cursor-pointer" (click)="changeStateSendEmail(0)">
                                                                {{ simSwapOrder.showSendEmail ? 'Ocultar eSIM por e-mail' : 'Enviar eSIM por e-mail' }}
                                                            </span>
                                                            <span *ngIf="simSwapOrder.is_esim == typeCard.esim && simSwapOrder.show_qr && simSwapOrder.pin"><a (click)="changeStateInfoEsim(0)" class="px-2 mx-2 custom-info cursor-pointer a-link">
                                                                {{ hideEsim(0) ? 'Ver' : 'Ocultar'}} eSIM</a>
                                                            </span>
                                                        </ng-container>
                                                    </span>

                                                </div>
                                                <div class="resumen mb-4" *ngIf="simSwapOrder.show_qr && !hideEsim(0) && simSwapOrder.pin">
                                                    <div>
                                                        <div class="px-4 pb-2">
                                                            <p><b class="col-6 px-0">PIN: </b><span class="simyo">{{ simSwapOrder.pin }}</span></p>
                                                            <img [src]="simSwapOrder.qr_code" [width]="150" class="img-fluid" alt="Qr code"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="(statusSendQR$ | async) as statusSendQR">
                                                    <div class="resumen d-flex align-items-center p-3 row" *ngIf="simSwapOrder.showSendEmail">
                                                        <div class="col-12 px-0">
                                                            <mat-form-field class="col-6 me-3">
                                                                <mat-label>Correo electrónico</mat-label>
                                                                <input matInput type="text" disabled [ngModel]="simSwapOrder.email">
                                                            </mat-form-field>
                                                            <button (click)="sendEmail()" target="_blank" class="btn btn-black-outline btn-sm" [disabled]="statusDisableSend.includes((statusSendQR$ | async))">
                                                                <b>{{getTextButtonSendQR((statusSendQR$ | async))}}</b>
                                                            </button>
                                                        </div>
                                                        <mat-error class="col-12 px-0" *ngIf="(statusSendQR$ | async) == 'error'">
                                                            <p>Error al enviar el email. Inténtalo de nuevo más tarde</p>
                                                        </mat-error>
                                                        <p class="message-status success mb-0" *ngIf="(statusSendQR$ | async) == 'success'">El email se ha enviado correctamente.</p>
                                                        <p class="message-status block mb-0" *ngIf="(statusSendQR$ | async) == 'blocked'">{{messageBlocked}}</p>
                                                    </div>
                                                </ng-container>
                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="simSwapOrder.is_esim == typeCard.sim">
                                                    <!-- ICCID -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                        ICCID nuevo: <b>{{ simSwapOrder.new_iccid }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- Motivo del cambio -->
                                                    <span class="sidebar-info5"><img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                        Motivo del cambio: <b>{{ simSwapOrder.reason }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="simSwapOrder.status && simSwapOrder.status !== ''">
                                                    <!-- Estado duplicado -->
                                                    <span class="sidebar-info5"><img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                        Estado duplicado: <b>{{ superStatusTranslate[simSwapOrder.status] }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="simSwapOrder.status === superStatus.active">
                                                    <!-- Fecha de activación -->
                                                    <span class="sidebar-info5"><img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                        Fecha de activación: <b>{{ simSwapOrder.status_obtained_at | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="simSwapOrder.status === superStatus.in_process || simSwapOrder.status === superStatus.active">
                                                    <!-- Estado documentacion -->
                                                    <span class="sidebar-info5"><img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                        Estado documentación: <b>{{ simSwapOrder.doc_status + (simSwapOrder.revision_date ? (' (' + (simSwapOrder.revision_date | date: 'dd/MM/yyyy, HH:mm') + ')') : '') }}</b>
                                                    </span>
                                                </div>
                                                <br>
                                                <ng-container *ngIf="priceSwapSim > 0">
                                                    <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                        <span class="sidebar-info">
                                                            <b>Total</b>
                                                        </span>
                                                        <small class="text-muted trash-carrito">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info2">
                                                                <b>{{ (priceSwapSim).toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                    <hr>
                                                    <!-- Importe Total -->
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <h5 class="total-color">
                                                            <b>Pago total</b>
                                                        </h5>
                                                        <h5 class="text-auto">
                                                            <b>{{ (priceSwapSim).toFixed(2) }} €</b>
                                                        </h5>
                                                    </div>
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <span class="total-color">
                                                            <b>IVA incluido</b>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- INCIDENCIAS -->
    <div class="container mt-2" *ngIf="simSwapOrder.doc_warning && simSwapOrder.doc_warning !== ''" id="incidencias">
        <div class="row">
            <div class="col-md-12">
                <div class="accordion">
                    <div class="card mb-3">
                        <div class="card-header bg-rm">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 class="subtitulo-pack2 ms-3">
                                        <b>Incidencias</b>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="bg-white">
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item cuerpo-pack-2">
                                            <h5 class="tar-name-7 mt-2 ms-3">{{ simSwapOrder.doc_warning }}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- SECCIÓN DE DOCUMENTOS-->
    <div class="container firma mt-2" *ngIf="simSwapOrder.status === superStatus.in_process || simSwapOrder.status === superStatus.active">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ms-3">
                                            <b>Firma justificante y documentación</b>
                                        </h6>
                                    </div>
                                    <small class="text-muted cursor-pointer" title="{{ simSwapOrder.doc_annotation && simSwapOrder.doc_annotation !== '' ? simSwapOrder.doc_annotation : '' }}">
                                        <div class="checkmark{{ simSwapOrder.doc_status && simSwapOrder.doc_status === simyoDocStatus.ok ? '' : '-disabled' }}"></div>
                                    </small>
                                </div>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item cuerpo-pack-2">
                                    <ng-container *ngIf="!(simSwapOrder.doc_status && simSwapOrder.doc_status === simyoDocStatus.ok && simSwapOrder.documents && !simSwapOrder.documents.length)">
                                        <h5  class="tar-name-7 mt-2 ms-3">Documentos subidos{{ simSwapOrder.doc_status && simSwapOrder.doc_status !== simyoDocStatus.ok && simSwapOrder.doc_annotation && simSwapOrder.doc_annotation !== '' ? (': ' + simSwapOrder.doc_annotation) : '' }}</h5>
                                        <div *ngFor="let file of simSwapOrder.documents" class="d-flex align-items-start mt-4 ms-3 simyo cursor-pointer" (click)="descargar(file)">
                                            <div class="me-2 d-flex align-items-center">
                                            <span class="{{ convertStatusClass(file.status) }}" title="{{file.annotation}}"></span>
                                            </div>
                                            <div>
                                            <div class="d-flex align-items-center tar-name-8" style="margin-top: -6px;">
                                                <img src="./assets/img/file.svg" class="img-fluid ico-title me-2" width="13">
                                                <span>{{ file.file_name }}</span>
                                                <span *ngIf="file.downloaded" class="spinner-grow spinner-grow-sm ms-2" role="status" aria-hidden="true"></span>
                                            </div>
                                        
                                            <ng-container *ngIf="file.annotation && file.annotation !== '' && file.status !== 'OK'">
                                                <div class="mt-1 text-dark">
                                                Comentario: {{ file.annotation }}
                                                </div>
                                            </ng-container>
                                            </div>
                                        </div>
                                        <!--< class="mt-4 mt-2 mb-2 d-flex justify-content-center">
                                            <a href="#!" (click)="showErrorUploadFile = false" data-bs-toggle="modal" data-bs-target="#documentacion" class="btn btn-simyo-o2 btn-sm d-block w-100">
                                                Subir documentación
                                            </a>
                                        </div>-->
                                    </ng-container>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
</ng-container>

<!-- MODALES -->
<!--
<div class="modal fade" id="documentacion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="display: none;" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header bg-simyo-grey">
                <h5 class="modal-title white">Documentación</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><img src="./assets/img/times-white.svg" class="img-fluid ico-title" width="20"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <ng-container *ngFor="let file of simSwapOrder?.documents">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>
                                        <span class="badge rounded-pill badge-simyo">Subido</span>
                                    </h6>
                                </div>
                                <small class="text-muted">
                                    <h6 class="subtitulo-pack3">{{ file.file_name }}</h6>
                                </small>
                            </div>
                            <div class="bb-line"></div>
                            <br>
                        </ng-container>

                        <div class="area">
                            <div id="dropZone"
                                (drop)="dropFile($event)"
                                (dragover)="allowDrop($event)"
                                (click)="inputFile.click()">
                                <img *ngIf="!uploadingFile"
                                    src="./assets/img/upload-cloud.svg"
                                    id="dropZoneImg"
                                    class="img-fluid mb-2"
                                    width="35">
                                <img *ngIf="uploadingFile"
                                    src="./assets/img/spinner-simyo.svg"
                                    class="img-fluid"
                                    width="30">
                                <input type="file" name="file" id="fileBtn" (change)="fileChanged($event)" style="visibility: hidden; width:0; height:0" #inputFile>
                                <br>
                                <span style="font-size: medium;">Haz click sobre el icono para seleccionar un archivo o arrastralo al área de punteada</span>
                            </div>
                        </div>
                    </div>
                    <div class="mx-4" *ngIf="showErrorUploadFile">
                        Error al subir documentación. Por favor, inténtelo de nuevo más tarde
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
