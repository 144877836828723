// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* export const environment = {
    production: false,
    version: '2.1.58',
    comunEndpoint: 'https://int.ms-comun.digo.tecalis.dev/',
    amenaEndpoint: 'https://int.ms-amena.digo.tecalis.dev/',
    rmEndpoint: 'https://int.ms-repmovil.digo.tecalis.dev/',
    simyoEndPoint: 'https://int.ms-simyo.digo.tecalis.dev/',
    orangeEndPoint: 'https://int.ms-orange.digo.tecalis.dev/',
    oldDigoEndPoint: 'https://int-b.dev.tecalis.com/',
    digoEndPoint: 'https://int.digo.tecalis.dev/',
    masmovilEndpoint: 'https://ms-masmovil.lamarr.digo.tecalis.dev',
    digoApiKey: 'theyUJu3tes@efaswejahEZ@afR5Fenu',
    googleMapsCountry: 'es',
    showRM: true,
    showSimyoTerminals: true,
    piloto: 'piloto_pdv',
    showSkipKYC: true,
    enableMyAddress : true,
    contractNewBrands: 'https://agents.masstack.com/',
    contractPepephone: 'https://canales.pepephone.com/',
}; */

export const environment = {
    production: false,
    version: '2.1.58',
    comunEndpoint: 'https://ms-comun.lamarr.digo.tecalis.dev/',
    amenaEndpoint: 'https://ms-amena.lamarr.digo.tecalis.dev/',
    rmEndpoint: 'https://ms-repmovil.lamarr.digo.tecalis.dev/',
    simyoEndPoint: 'https://ms-simyo.lamarr.digo.tecalis.dev/',
    orangeEndPoint: 'https://ms-orange.lamarr.digo.tecalis.dev/',
    oldDigoEndPoint: 'https://int-b.dev.tecalis.com/',
    digoEndPoint: 'https://int-b.dev.tecalis.com/digo/current/',
    masmovilEndpoint: 'https://ms-masmovil.lamarr.digo.tecalis.dev',
    digoApiKey: 'theyUJu3tes@efaswejahEZ@afR5Fenu',
    googleMapsCountry: 'es',
    showRM: true,
    showSimyoTerminals: true,
    piloto: 'piloto_pdv',
    showSkipKYC: true,
    enableMyAddress : true,
    contractNewBrands: 'https://agents.masstack.com/',
    contractPepephone: 'https://canales.pepephone.com/',
};

