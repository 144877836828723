<!-- Filtro rápido y estadísticas -->
<div class="content">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-12">
                    <div class="btn btn-black-noclick btn-sm mt-3">
                        <strong>Actividad</strong> ({{ currentMonth }}):
                    </div>
                    <button type="button" class="btn btn-black-click btn-sm mt-3" (click)="getActivationThisMonth()">
                        <strong>{{ stats?.activations_current_month || '0' }}</strong> activaciones
                    </button>
                    <button type="button" class="btn btn-black-click btn-sm mt-3 mr-3" (click)="getRequestThisMonth()">
                        <strong>{{ stats?.orders_current_month || '0' }}</strong> solicitudes
                    </button>

                    <div class="btn btn-grey3-noclick btn-sm mt-3 font-weight-normal">
                        <strong>Alertas</strong>:
                    </div>

                    <button type="button" class="btn btn-grey3 btn-sm mt-3 font-weight-normal" (click)="getSuspendedThisMonth(); isAlertView = true">
                        <strong>{{ stats?.cancelleds_current_month || '0' }}</strong> canceladas
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="resumen d-flex justify-content-between align-items-center">
                <div class="form-inline">
                    <mat-form-field class="mb-2">
                        <mat-label>
                            <img src="../../../../assets/img/search2.svg" class="img-fluid icon-search" width="12"> Buscar por nombre o DNI
                        </mat-label>
                        <input matInput id="search-client" type="text" oninvalid="" (input)="searchClient($event.target.value)">
                        <i class="bar"></i>
                    </mat-form-field>
                </div>
                <!-- Filter -->
                <small class="text-muted">
                    <a href="#" data-toggle="collapse" data-target="#filtros" [attr.aria-expanded]="isCollapsed" aria-controls="filtros" title="Filtros avanzados">
                        <img src="../../../../assets/img/filter.svg" class="img-fluid icon-filter" width="23">
                    </a>
                </small>
            </div>
        </div>
    </div>
</div>

<!-- Filtros avanzados y cuerpo -->
<div (window:scroll)="doSomethingOnWindowScroll($event)">
    <div class="row">
        <div class="col-lg-12">
            <div id="filtros" class="filters-collapse collapse" [ngbCollapse]="isCollapsed">

                <form [formGroup]="formGroup">
                    <div class="form-row">
                        <div class="col-lg-9">
                            <mat-form-field>
                                <mat-label>Nombre y/o apellidos</mat-label>
                                <input matInput formControlName="full_name">
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Nº teléfono móvil</mat-label>
                                <input matInput formControlName="msisdn_movil" maxlength="9">
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <app-datepicker-custom
                                title="Fecha de solicitud desde"
                                [form]="formGroup"
                                name="min_date"
                                [maxDate]="maxDate"
                                (onChangeDate)="setMinDate($event)"
                            ></app-datepicker-custom>
                        </div>

                        <div class="col-lg-4">
                            <app-datepicker-custom
                                title="Fecha de solicitud hasta"
                                [form]="formGroup"
                                name="max_date"
                                [minDate]="minDate"
                                (onChangeDate)="setMaxDate($event)"
                            ></app-datepicker-custom>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Estado</mat-label>
                                <select matNativeControl formControlName="status">
                                    <option value="">Todas</option>
                                    <option *ngFor="let status of statuses" [ngValue]="status.translation">
                                        {{ status.translation }}
                                    </option>
                                </select>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Tipo de alta</mat-label>
                                <select matNativeControl formControlName="contract_type">
                                    <option value="">Todas</option>
                                    <option *ngFor="let type of listType" [value]="type.id">{{ type.label }}</option>
                                </select>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Ordenar por fecha</mat-label>
                                <select matNativeControl formControlName="sort_by_date">
                                    <option *ngFor="let type of listSortByDate" [value]="type.id">{{ type.label }}</option>
                                </select>
                            </mat-form-field>
                        </div>
                    </div>

                    <button class="btn btn-black-outline mb-1 btn-sm" type="button" (click)="advanceSearch()">Aplicar filtro</button>
                </form>

                <br>
            </div>
        </div>
    </div>
</div>

<div class="content" *ngIf="ordersFiltered.length > 0">
    <div class="row">
        <div class="col-lg-12">
            <!--- \\\\\\\Cliente -->
            <div class="card gedf-card mb-3" *ngFor="let order of ordersFiltered; let i = index">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <ul class="nav nav-pills card-header-pills topnav" id="customer-tab-{{ i }}" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link text-list active" data-toggle="tab" href="#cliente-{{ i }}" role="tab" aria-controls="cliente" aria-selected="true">Cliente</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                            <div>
                                <!-- Fecha de tramitación -->
                                <span class="info-secundaria dir-text2 d-inline-block">
                                    Fecha de tramitación: {{ getOrderTramitationDate(order) }} <br>
                                </span>
                                <!-- Usuario -->
                                <span class="badge badge-light text-dark badge-shape" *ngIf="pdv">
                                    {{ pdv.info.username | truncate: 290 }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body bg-white">
                    <div class="tab-content" id="customer-tab-content-{{ i }}">
                        <div class="tab-pane fade show active" id="cliente-{{ i }}" role="tabpanel" aria-labelledby="">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list mb-0">
                                            <span class="text-list" *ngIf="order.customer_full_name != null && order.customer_full_name != ''">
                                                <b>{{ order.customer_full_name }}</b>
                                                <!-- <b *ngIf="order.document_number && order.document_number !== ''">
                                                    - Documento: {{ order.document_number }}
                                                </b> -->
                                                <br><br>
                                            </span>

                                            <!-- Tipo de alta -->
                                            <span class="font-list">
                                                <img src="../../../../assets/img/package2.svg" class="img-fluid" width="13">
                                                Tipo de alta:
                                                <b *ngIf="order.type === 'broadband'">
                                                    Alta fibra ({{ order.broadbands[0].rate }})
                                                </b>

                                                <b *ngIf="order.type === 'convergent'">
                                                    Alta fibra ({{ order.broadbands[0].rate }})
                                                </b>
                                                <b *ngIf="order.type === 'mobile' && order.mobiles && order.mobiles.length">
                                                    {{ getTitleLine(order.mobiles[0]) + ' (' + order?.mobiles[0]?.rate + ')' }}
                                                </b>
                                                <b *ngIf="order.type === 'sva' && order.services && order.services.length">
                                                    Servicio
                                                </b>
                                            </span>

                                            <!-- Estado fibra -->
                                            <span class="font-list" *ngIf="order.broadbands && order.broadbands.length">
                                                <br>
                                                <img src="../../../../assets/img/info.svg" class="img-fluid" width="13">
                                                Estado fibra:
                                                <span>
                                                    <b [ngClass]="{'cursor-pointer': order.broadbands[0]?.status}">{{ order.broadbands[0]?.status && order.broadbands[0]?.status !== '' ? order.broadbands[0]?.status : '' }} </b>
                                                </span>
                                            </span>

                                            <span class="font-list" *ngIf="order?.broadbands[0]?.msisdn && order?.broadbands[0]?.msisdn !== 'PENDIENTE DE ASIGNAR' || order?.services[0]?.msisdn && order?.services[0]?.msisdn !== 'PENDIENTE DE ASIGNAR'">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                {{ order.broadbands[0].is_portability ? 'Portabilidad ' : 'Alta ' }} línea fija: <b>{{ order?.broadbands[0]?.msisdn }}</b>
                                            </span>

                                            <span class="font-list" *ngIf="order.mobiles && order.mobiles.length">
                                                <br>
                                                <img src="../../../../assets/img/phone.svg" class="img-fluid" width="13">
                                                {{  order.type === 'convergent' ? order.mobiles[0].is_portability ? 'Portabilidad 1º línea' : 'Alta 1º línea' : 'Línea'}}  móvil {{ order.mobiles[0].contract_type && order.mobiles[0].contract_type !== '' ? (order.mobiles[0].contract_type === 'prepaid' ? 'prepago' : 'pospago') : '' }}: <b>{{ (order.mobiles[0].msisdn && order.mobiles[0].msisdn !== '' ? order.mobiles[0].msisdn : 'Pendiente de asignar') + ' - ' + (order.mobiles[0].terminal ? order.mobiles[0].terminal : 'Sin Terminal') }}</b>
                                            </span>

                                            <span class="font-list" *ngIf="order.mobiles && order.mobiles.length">
                                                <br>
                                                <img src="../../../../assets/img/info.svg" class="img-fluid" width="13">
                                                Estado línea móvil: <b>{{ order.mobiles[0].status }} </b>
                                            </span>

                                            <!-- Estado servicio -->
                                            <span class="font-list" *ngIf="order.services && order.services.length">
                                                <br>
                                                <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                Servicio {{ order.services[0].rate }}: <b>{{ showMsisdnTerminal(order)}}</b>
                                            </span>

                                            <span class="font-list" *ngIf="order.services && order.services.length">
                                                <br>
                                                <img src="../../../../assets/img/info.svg" class="img-fluid" width="13">
                                                Estado servicio: <b>{{ order.services[0].status }} </b>
                                            </span>

                                            <br *ngIf="order.mobiles && (order.mobiles.length > 1 || (order.mobiles.length > 0  && order.type === 'convergent'))">
                                            <br *ngIf="order.mobiles && (order.mobiles.length > 1 || (order.mobiles.length > 0  && order.type === 'convergent'))">
                                        </h5>
                                    </div>

                                    <div class="col-lg-12">
                                        <span *ngFor="let mobile of order.mobiles; let i = index">
                                            <div *ngIf="i > 0">
                                                <div class="font-list info-secundaria" *ngIf="mobile.rate">
                                                    <img src="../../../../assets/img/package2.svg" class="img-fluid" width="13">
                                                    {{ mobile.is_portability ? 'Portabilidad ' : 'Alta ' }} {{ (i + 1) + 'ª línea móvil' }} {{ mobile.contract_type && mobile.contract_type !== '' ? (mobile.contract_type === 'prepaid' ? 'prepago' : 'pospago') : '' }}:
                                                    <b>
                                                        <!-- {{ mobile.rate.data + ' + ' + mobile.rate.voice + (mobile.bonos && mobile.bonos.length ? ' + ' + mobile.bonos.length + ' bono(s)' : '') + ': ' + (getPriceMobile(mobile)|toFixedDecimals) + '€ - ' + (mobile.new_msisdn ? mobile.new_msisdn : 'Pendiente de asignar') + ' - ' + (mobile.terminal_oid ? mobile.terminal_oid?.terminal?.brand + ' ' + mobile.terminal_oid?.terminal?.model : 'Sin Terminal') }} -->
                                                        {{ mobile.rate + ' - ' + (mobile.msisdn ? mobile.msisdn : 'Pendiente de asignar') + ' - ' + (mobile.terminal ? mobile.terminal : 'Sin Terminal') }}
                                                    </b>
                                                </div>
                                                <div class="font-list info-secundaria mb-2" *ngIf="mobile.status && mobile.status !== ''">
                                                    <img src="../../../../assets/img/info.svg" class="img-fluid" width="13">
                                                    {{ 'Estado ' + (i + 1) + 'ª línea móvil: ' }}<b>{{ mobile.status }}</b>
                                                </div>
                                            </div>
                                        </span>

                                        <span *ngFor="let service of order.services; let i = index">
                                            <div *ngIf="i > 0">
                                                <div class="font-list info-secundaria" *ngIf="service.rate">
                                                    <img src="../../../../assets/img/package2.svg" class="img-fluid" width="13">
                                                    Servicio {{ (i + 1) + 'ª ' }}:
                                                    <b>
                                                        <!-- {{ mobile.rate.data + ' + ' + mobile.rate.voice + (mobile.bonos && mobile.bonos.length ? ' + ' + mobile.bonos.length + ' bono(s)' : '') + ': ' + (getPriceMobile(mobile)|toFixedDecimals) + '€ - ' + (mobile.new_msisdn ? mobile.new_msisdn : 'Pendiente de asignar') + ' - ' + (mobile.terminal_oid ? mobile.terminal_oid?.terminal?.brand + ' ' + mobile.terminal_oid?.terminal?.model : 'Sin Terminal') }} -->
                                                        {{ service.rate + ' - ' + (service.msisdn ? service.msisdn : 'Pendiente de asignar') + ' - ' + (service.terminal ? service.terminal : 'Sin Terminal') }}
                                                    </b>
                                                </div>
                                                <div class="font-list info-secundaria mb-2" *ngIf="service.status && service.status !== ''">
                                                    <img src="../../../../assets/img/info.svg" class="img-fluid" width="13">
                                                    {{ 'Estado ' + (i + 1) + 'ª línea móvil: ' }}<b>{{ service.status }}</b>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-toolbar justify-content-end mb-3">
                            <div class="btn-group">
                                <a [routerLink]="['/guuk/order/', order.order_id]" [state]="{data: order}" class="btn btn-guuk-o2 btn-sm">
                                    Ver más detalles
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Cargando -->
<div *ngIf="!loaded" class="mt-2 mb-5">
    <h5 align="center" class="tar-name-preload">
        Obteniendo ordenes...
    </h5>
    <div align="center">
        <img class="" src="../../../../assets/img/spinner-guuk.svg" width="30" alt="Cargando...">
    </div>
    <br>
</div>

<!-- Error -->
<div *ngIf="errorSearch" class="mt-5">
    <h5 align="center" class="tar-name-preload">
        Se ha producido un error, inténtalo de nuevo
    </h5>
</div>

<!-- No hay pedidos -->
<div *ngIf="loaded && ordersFiltered.length == 0 && !errorSearch" class="mt-5">
    <h5 align="center" class="tar-name-preload">
        No se han encontrado ordenes.
    </h5>
</div>

<!-- Cargar más pedidos  -->
<div align="center" class="mt-4" *ngIf="orderListRequest.page < lastPage && loaded && !errorSearch">
    <button class="btn btn-black-outline btn-block mb-1" (click)="loadMore()">
        Cargar más
    </button>
    <br>
</div>
