import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, ViewEncapsulation, ɵɵtrustConstantResourceUrl} from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { OrangeRate, OrangeRatesWP } from 'src/app/orange/models/masterAllResponse';
import { AppState } from 'src/app/orange/redux';
import { setRatePrepaid, setTotal, setWelcomePackPrepaid } from 'src/app/orange/redux/orange.actions';
import { IOrangePrepaidState, IRefillAmount } from 'src/app/orange/redux/orange.state';
import { orangePrepaidTypes, orangeRefillAmounts, orangeWelcomePackCodes } from 'src/app/shared/constantes';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import {anyFormValid, isValueDup, isValueDupWith} from 'src/app/utils/validators/any-form-valid';
import { environment } from 'src/environments/environment';
import {FloatLabelType} from '@angular/material/form-field';
import {OrangeShoppingCartService} from '../../../services/orange-shopping-cart.service';
import {OrangePrepaidHiringService} from '../../../services/orange-prepaid-hiring.service';
import { tap, shareReplay } from 'rxjs/operators';
import { OperatorsService } from 'src/app/orange/services/operators.service';
import { MatRadioChange } from '@angular/material/radio';
import { eSimConstants } from '../../../models/eSimConstants';

@Component({
    selector: 'app-orange-prepaid-configuration',
    templateUrl: './orange-prepaid-configuration.component.html',
    styleUrls: ['./orange-prepaid-configuration.component.css', '../../../../../assets/css/orange-theme.css'],
    encapsulation: ViewEncapsulation.None,
})
export class OrangePrepaidConfigurationComponent implements OnInit {
    @ViewChildren('formRow') rows: QueryList<ElementRef>;

    @Input() configurationFormGroup: FormGroup;
    @Input() userDataFormGroup: FormGroup;
    @Input() stepper: MatStepper;
    @Input() pdv: Ipdv;
    @Input() type: string;

    @Output() goTop = new EventEmitter<boolean>();

    public orange$: Observable<IOrangePrepaidState>;
    public moreInfoRate: OrangeRate = null;
    public isCollapsedWelcomePack: boolean = true;
    public isCollapsedRates: boolean = true;
    public orangeWelcomePackCodes = orangeWelcomePackCodes;
    public COUNT_SIMS_PREPAID: number = 4;
    public orangeRefillAmounts: IRefillAmount[] = orangeRefillAmounts;
    public allPermissions = allPermissions;
    public showHaveToSelectWP: boolean =  false;
    public baseUrl: string;
    public MAX_MANUAL_AMOUNT: number = 50;
    public MIN_MANUAL_AMOUNT: number = 30;
    public promo: string = "";
    public promo_desc: string = "";


    private orangeStateSubscription: Subscription;
    private isCustomSelected: boolean = false;
    public floatLabelControl = new FormControl('never' as FloatLabelType);

    public eSimConstants = eSimConstants;
    public rateEsimSelected:string;

    public portabilityOptions = [
        {value: "", label: "Seleccione modo de pago"},
        {value: "T", label: "Prepago"},
        {value: "C", label: "Contrato"},
    ]

    constructor(
        private orangeStore: Store<AppState>,
        private modalService: NgbModal,
        public permSv: PermissionService,
        private orangePrepaidService: OrangePrepaidHiringService,
        private shoppingCartService: OrangeShoppingCartService,
        public operatorsService: OperatorsService
    ) {
        this.shoppingCartService.hiddeShoppingCart.emit(false);
        this.baseUrl = environment.digoEndPoint;
        this.orange$ = orangeStore.pipe(select('orangePrepaid'), shareReplay());
        this.orangeStateSubscription = this.orange$.subscribe((c: IOrangePrepaidState) => {
            // Un WP con muchas tarifas
            if (c?.welcome_pack_selected && !c?.rate_selected && c.master?.rates?.length === 1 && c.master?.rates[0].rates?.length > 1) {
                this.configurationFormGroup.get('welcome_pack').patchValue(c.welcome_pack_selected);
                this.checkWelcomePackSelected(c.welcome_pack_selected);
                this.isCollapsedWelcomePack = true;
                this.isCollapsedRates = false;
                this.orangeStateSubscription.unsubscribe();
            }

            // Un WP con una tarifa
            if (c?.welcome_pack_selected && c?.rate_selected && !this.configurationFormGroup.get('rate').value) {
                this.isCollapsedRates = true;
                this.isCollapsedWelcomePack = true;
                this.configurationFormGroup.get('welcome_pack').patchValue(c.welcome_pack_selected);
                this.configurationFormGroup.get('rate').patchValue(c.rate_selected);
                this.checkWelcomePackSelected(c.welcome_pack_selected);
                this.addValidatorBalance(c.rate_selected);
                this.orangeStore.dispatch(setTotal({total: +c.rate_selected.price}));
                this.orangeStateSubscription.unsubscribe();
            }

            // Muchos WP
            if (!c?.welcome_pack_selected && c.master?.rates?.length > 1) {
                this.isCollapsedRates = true;
                this.isCollapsedWelcomePack = false;
                this.orangeStateSubscription.unsubscribe();
            }
        });
        this.shoppingCartService.removeMsisdn.subscribe((index: number) => {
            if (index != null && index !== 0) {
                const v = this.configurationFormGroup.get('msisdns').value;
                const values = [...v];

                values.splice(index, 1);
                values.push('');

                this.configurationFormGroup.get('msisdns').patchValue(values);
                (this.configurationFormGroup.get('msisdns') as FormArray).controls.map(c => c.updateValueAndValidity());
            }
        })
        this.shoppingCartService.focusMsisdn.subscribe((index: number) => {
            if (index != null) {
                this.rows.get(index).nativeElement.querySelector('input').focus();
            }
        })
        this.shoppingCartService.removeLine.subscribe((index: number) => {
            const lines = this.configurationFormGroup.get('esim_number_lines').value;
            if (lines != null && lines !== 0) {
                const newLines = lines - 1;
                this.configurationFormGroup.get('esim_number_lines').patchValue(newLines > 1 ? newLines : 1);
                (this.configurationFormGroup.get('esim_number_lines') as FormControl).updateValueAndValidity();
            }
        })


    }

    ngOnInit(): void {
        const arrayLength = (this.permSv.hasPermFromV2(allPermissions.orange.permite_prepaid_activate_multiple) && this.type === orangePrepaidTypes.alta) ? this.COUNT_SIMS_PREPAID : 1;
        const validators = [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[6-7][0-9]{0,8}$')];

        if (arrayLength === 1) {
            validators.push(Validators.required);
        }

        Array(arrayLength)
            .fill('')
            .map(msisdn => (this.configurationFormGroup.get('msisdns') as FormArray).push(new FormControl(msisdn, validators)));

        this.configurationFormGroup.statusChanges.subscribe(status => {
            if(status === 'VALID') {
                this.moveBottom();
            }
        })

        this.configurationFormGroup.get('type_operation').valueChanges.subscribe(value => {
            this.changeTypeOperation(value);
        });
    }

    public openModal(modal, rate: OrangeRate) {
        this.moreInfoRate = rate;
        this.modalService.open(modal, {size: 'lg', centered: true});
    }

    public openModalInfo(modal) {
        console.log('openModal');
        this.modalService.open(modal, {size: 'md', centered: true});
    }

    public next() {
        this.stepper.next();
        this.goTop.emit(true);
    }

    public setWelcomePack(welcomePack: OrangeRatesWP) {
        this.orangeStore.dispatch(setWelcomePackPrepaid({welcomePack: welcomePack}));
        this.configurationFormGroup.get('welcome_pack').patchValue(welcomePack);
        // Seleccionamos un WP y solo tiene una tarifa
        if (welcomePack.rates?.length === 1) {
            this.setRate(welcomePack.rates[0]);
            this.isCollapsedRates = true;
            this.isCollapsedWelcomePack = true;
        }

        // Seleccionamos un WP con varias tarifas
        if (welcomePack.rates?.length > 1) {
            // Tarifa ya seleccionada previamente, comprobamos si es compatible
            if (this.configurationFormGroup.get('rate').value) {
                const found = welcomePack.rates.find(pack => pack.rate_id === this.configurationFormGroup.get('rate').value.rate_id);
                if (!found) {
                    this.configurationFormGroup.get('rate').patchValue(null);
                    this.orangeStore.dispatch(setTotal({total: 0}));
                    this.orangeStore.dispatch(setRatePrepaid({rate: null}));
                }
            }
            this.isCollapsedRates = false;
            this.isCollapsedWelcomePack = true;
            this.moveBottom();
        }

        this.checkWelcomePackSelected(welcomePack);
    }

    public setRate(rate: OrangeRate) {
        const isEsimAndRegistration = this.configurationFormGroup.get('welcome_pack').value.is_esim && this.configurationFormGroup.get('type_operation').value === eSimConstants.registrationTypeOperation;
        const lines = isEsimAndRegistration ? this.configurationFormGroup.get('esim_number_lines').value : this.configurationFormGroup.get('msisdns').value.filter(item => item !== '').length;

        this.configurationFormGroup.get('rate').patchValue(rate);
        this.addValidatorBalance(rate);
        this.orangeStore.dispatch(setTotal({total: +rate.price * lines}));
        this.orangeStore.dispatch(setRatePrepaid({rate: rate}));
        this.isCollapsedRates = true;
        this.isCollapsedWelcomePack = true;
    }

    private removeRate() {
        this.configurationFormGroup.get('rate').patchValue(null);
        this.addValidatorBalance(null);
        this.orangeStore.dispatch(setTotal({total: 0}));
        this.orangeStore.dispatch(setRatePrepaid({rate: null}));
    }

    public changeStateCollapseRates() {
        if (this.isCollapsedRates) {
            this.moveBottom();
        }

        this.isCollapsedWelcomePack = true;
        this.isCollapsedRates = false;
        this.removeRate();
    }

    public changeStateCollapseWelcomePack() {
        if (this.isCollapsedWelcomePack) {
            this.moveBottom();
        }

        this.isCollapsedRates = true;
        this.isCollapsedWelcomePack = false;
        this.removeRate();
    }

    public moveBottom() {
        setTimeout(() => {
            window.scroll({top: document.getElementById('allConfRates').getBoundingClientRect().top + window.scrollY, behavior: 'smooth'});
        }, 200);
    }


    public isWelcomePackSelected() {
        this.showHaveToSelectWP = this.configurationFormGroup.get('welcome_pack').value ? false : true;
    }

    public changeRechargeAmmout(recharge: IRefillAmount, state: 'add' | 'remove') {
        if (state === 'add') {
            recharge.amount = +recharge.amount + 5;
        }
        if (state === 'remove') {
            recharge.amount = +recharge.amount - 5;
        }
        if (this.isCustomSelected) {
            this.configurationFormGroup.get('balance').patchValue(recharge.amount);
        }
    }

    public trackItem(index: number, item: IRefillAmount) {
        return item.amount;
    }

    public changeAmount(recharge: IRefillAmount) {
        this.isCustomSelected = recharge.id === 'custom';
    }

    private checkWelcomePackSelected(rate: OrangeRatesWP) {
        this.isWelcomePackSelected();

        // eSIM
        if (rate.is_esim) {
            /* Si el código es eSIM debemos:
                No se introducen msisdns
                El email es obligatorio
            */
            this.configurationFormGroup.get('msisdns').clearValidators();
            this.configurationFormGroup.get('msisdns').updateValueAndValidity();
            (this.configurationFormGroup.get('msisdns') as FormArray).controls
                .map(control => {
                    control.patchValue('');
                    control.clearValidators();
                    control.updateValueAndValidity();
                });
            this.userDataFormGroup.get('email').setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z]{2,4}$')]);
            this.userDataFormGroup.get('email').updateValueAndValidity();

            this.configurationFormGroup.get('type_operation').setValidators([Validators.required]);
            this.configurationFormGroup.get('type_operation').updateValueAndValidity();
            this.configurationFormGroup.patchValue({
                type_operation: eSimConstants.registrationTypeOperation,
                esim_number_lines: 1
            });
        } else {
            /* Si el código no es eSIM debemos:
                Debe haber al menos un msisdn
            */

            this.configurationFormGroup.get('msisdns').setValidators(anyFormValid());
            this.configurationFormGroup.get('msisdns').updateValueAndValidity();
            (this.configurationFormGroup.get('msisdns') as FormArray).controls
                .map((control, index) => {
                    const validators = [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[6-7][0-9]{0,8}$'), isValueDup()];

                    if (index === 0) {
                        validators.push(Validators.required);
                    }

                    control.setValidators(validators);
                    control.updateValueAndValidity();
                });
        }

        // Alta
        if (rate.is_prepaid && !rate.is_esim) {
            /* Si el código no es eSIM debemos:
                El email no es obligatorio
            */
            this.userDataFormGroup.get('email').setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z]{2,4}$')]);
            this.userDataFormGroup.get('email').updateValueAndValidity();
        }

        // Portabilidad
        if (rate.is_portability) {
            this.configurationFormGroup.get('portability_operator').setValidators([Validators.required]);
            this.configurationFormGroup.get('portability_operator').updateValueAndValidity();

            this.configurationFormGroup.get('portability_type_client').setValidators([Validators.required]);
            this.configurationFormGroup.get('portability_type_client').updateValueAndValidity();

            this.configurationFormGroup.get('portability_old_iccid').setValidators([Validators.required, Validators.minLength(19), Validators.maxLength(19), Validators.pattern('^[0-9]*$'), isValueDupWith('portability_new_iccid')]);
            this.configurationFormGroup.get('portability_old_iccid').updateValueAndValidity();

            this.configurationFormGroup.get('portability_new_iccid').setValidators([Validators.required, Validators.minLength(19), Validators.maxLength(19), Validators.pattern('^[0-9]*$'), isValueDupWith('portability_old_iccid')]);
            this.configurationFormGroup.get('portability_new_iccid').updateValueAndValidity();

            this.userDataFormGroup.get('email').setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z]{2,4}$')]);
            this.userDataFormGroup.get('email').updateValueAndValidity();
        }

        // Terminal
        if (rate.is_terminal) {
            this.configurationFormGroup.get('terminal_imei').setValidators([Validators.required, Validators.minLength(15), Validators.maxLength(15), Validators.pattern('^[0-9]*$')]);
            this.configurationFormGroup.get('terminal_imei').updateValueAndValidity();
        }
    }

    private addValidatorBalance(rate: OrangeRate) {
        this.configurationFormGroup.get('balance').patchValue(0);
        const saldoPDV = +this.pdv.info_recargas.saldo_promo + +this.pdv.info_recargas.saldo_dexga;
        this.orangeRefillAmounts.map(refillAmount => refillAmount.disabled = refillAmount.amount !== 0);
        if (saldoPDV === 0) {
            this.orangeRefillAmounts.map(refillAmount => refillAmount.disabled = refillAmount.amount !== 0);
        }
        if (saldoPDV > 0 && saldoPDV >= +rate?.price) {
            this.orangeRefillAmounts.map(refillAmount => refillAmount.disabled = refillAmount.amount !== 0 && saldoPDV < refillAmount.amount);
        }
    }

    public getTitle() {
        switch (this.type) {
            case orangePrepaidTypes.esim:
                return 'Configuración eSIM prepago';
            case orangePrepaidTypes.portabilidad:
                return 'Configuración Portabilidad';
            case orangePrepaidTypes.terminal:
                return 'Configuración Terminal + SIM';
            case orangePrepaidTypes.alta:
                return 'Configuración alta prepago';
            default:
                return 'Configuración alta prepago';
        }
    }

    public errorMsisdnsHandling = (control: any, error: string) => {
        if (control.invalid && (control.dirty || control.touched)) {
            return control.hasError(error);
        }
    }

    public errorHandling = (control: string, error: string) => {
        if (this.configurationFormGroup.get(control).invalid && (this.configurationFormGroup.get(control).dirty || this.configurationFormGroup.get(control).touched)) {
            return this.configurationFormGroup.controls[control].hasError(error);
        }
    }

    public numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;

        return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }

    public checkMsisdnValidity(control: FormControl) {
        if (control.valid) {
            this.orangePrepaidService.checkIfMsisdnIsValid(control.value).subscribe((r: any) => {
                if (!r.result) {
                    control.markAsTouched();
                    control.updateValueAndValidity();
                }
                control.setErrors(!r.result ? {used: true} : null);
            });
        }
    }

    public hayPromo(rates: any){
        var hay = false;
        rates.forEach(rate => {
            if (rate.promo !== null) {
                hay = true;
                this.promo = rate.promo;
                this.promo_desc = rate.promo_description;
            }
        });
        return hay;
    }

    public errorTypeOperationHandling = (control: any, error: string) => {
        if (control.invalid && (control.dirty || control.touched)) {
            return control.hasError(error);
        }
    }

    changeTypeOperation(value) {
        if(value === eSimConstants.registrationTypeOperation) {
            this.configurationFormGroup.get('portability_type_client').setValidators([]);
            this.configurationFormGroup.get('portability_type_client').updateValueAndValidity();

            this.configurationFormGroup.get('portability_old_iccid').setValidators([]);
            this.configurationFormGroup.get('portability_old_iccid').updateValueAndValidity();

            this.configurationFormGroup.get('portability_operator').setValidators([]);
            this.configurationFormGroup.get('portability_operator').updateValueAndValidity();

            this.configurationFormGroup.get('msisdns').clearValidators();
            this.configurationFormGroup.get('msisdns').updateValueAndValidity();
            (this.configurationFormGroup.get('msisdns') as FormArray).controls
                .map(control => {
                    control.patchValue('');
                    control.clearValidators();
                    control.updateValueAndValidity();
                });
            this.configurationFormGroup.patchValue({
                portability_type_client: null,
                portability_old_iccid: null,
                portability_operator: null,
                esim_number_lines: 1
            });
        } else {
            this.configurationFormGroup.get('portability_type_client').setValidators([Validators.required]);
            this.configurationFormGroup.get('portability_type_client').updateValueAndValidity();

            this.configurationFormGroup.get('portability_old_iccid').setValidators([Validators.required, Validators.minLength(19), Validators.maxLength(19), Validators.pattern('^[0-9]*$')]);
            this.configurationFormGroup.get('portability_old_iccid').updateValueAndValidity();

            this.configurationFormGroup.get('portability_operator').setValidators([Validators.required]);
            this.configurationFormGroup.get('portability_operator').updateValueAndValidity();

            this.configurationFormGroup.get('msisdns').setValidators(anyFormValid());
            this.configurationFormGroup.get('msisdns').updateValueAndValidity();
            (this.configurationFormGroup.get('msisdns') as FormArray).controls
                .map((control, index) => {
                    const validators = [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[6-7][0-9]{0,8}$'), isValueDup()];

                    if (index === 0) {
                        validators.push(Validators.required);
                    }

                    control.setValidators(validators);
                    control.updateValueAndValidity();
                });
            this.configurationFormGroup.patchValue({
                esim_number_lines: null
            });
        }

        this.configurationFormGroup.markAsPristine();
        this.configurationFormGroup.markAsUntouched();
    }

    changeRateEsimSelected(subtype: string) {
        this.rateEsimSelected = subtype;
    }

    filterRates(welcomePackSelected: any) {
        if(welcomePackSelected.is_esim === true && this.permSv.hasPermFromV2(allPermissions.orange.permite_view_rates_mundo_esim) === false) {
            return welcomePackSelected.rates.filter(rate => rate.name?.toLowerCase().includes(eSimConstants.defaultRateEsimSelected) === false);
        }

        if(welcomePackSelected.is_esim === true) {
            return welcomePackSelected.rates.filter(rate => rate.name?.toLowerCase().includes(this.rateEsimSelected));
        }

        return welcomePackSelected.rates;
    }

    esimNumberLinesChange($event: MatRadioChange) {
        console.log('cambio de lineas', $event.value);
        const rateSelected = this.configurationFormGroup.get('rate').value;
        this.orangeStore.dispatch(setTotal({total: +rateSelected.price * $event.value}));
    }

    setClassCssIfSelectedTypeRates(type) {
        if (this.rateEsimSelected === undefined) {
            return '';
        }
        return type === this.rateEsimSelected ? 'selected' : 'unselected';
    }

    showTabsEsim(data) {
        return this.permSv.hasPermFromV2(allPermissions.orange.permite_view_rates_mundo_esim) && data?.welcome_pack_selected?.is_esim;
    }

    handleClick() {
        console.log('Click recibido en el padre');
    }

}
