<div class="firma mt-2" *ngIf="orderDocuments && orderDocuments.length">
    <!-- Subida de documentación -->
    <ng-container *ngIf="documentationMode === documentationTypes.uploadDocumentation">
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-3">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="subtitulo-pack2">
                                    <b>Firma contrato y documentación</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body bg-white">
                        <div class="radio-btn-group space-between blue total mt-0">
                            <div class="radio m-0" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_digital_signature)">
                                <input id="digital" type="radio" [value]="'digital'" (click)="changeDocumentationUploadType($event.target.value)"
                                       [checked]="checkTypeSignSelected('digital')" [attr.disabled]="disabledDigital ? 'disabled' : null"/>
                                <label for="digital">
                                    <b style="font-size: 1.2rem;">Digital</b>
                                </label>
                            </div>

                            <mat-error *ngIf="(warningMsgChangeType && warningMsgChangeType !== '') || checkTypeSignSelected('manual')">
                                {{ warningMsgChangeType }}
                            </mat-error>

                            <div class="radio" *ngIf="showManualSignature()">
                                <input id="manual" type="radio" [value]="'manual'" (click)="changeDocumentationUploadType($event.target.value)"
                                       [checked]="checkTypeSignSelected('manual')" [attr.disabled]="disabledManual ? 'disabled' : null"/>
                                <label for="manual">
                                    <b style="font-size: .8rem;">Manual</b>
                                </label>
                            </div>

                            <div style="color: #ff5900; font-weight: bold; margin-left: 12px; text-align: center; width: 100%" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_digital_signature) && checkTypeSignSelected('manual') && !disabledDigital">
                                ¡Vuelve a la opción de firma digital! ¡Más rápido y sin tener que imprimir papel!
                            </div>
                        </div>
                        <!-- DIGITAL -->
                        <div class="mt-3 total" *ngIf="checkTypeSignSelected('digital')">
                            <ng-container *ngFor="let document of orderDocuments; let i = index">
                                <div class="d-flex justify-content-between align-items-center px-2 py-3" style="border: .5px solid #f1f1f1;">
                                    <div [ngClass]="{'col-8': document.hash && document.hash !== '' && document.status === 'signed', 'col-10': !(document.hash && document.hash !== '' && document.status === 'signed')}">
                                        <span class="dot-tables me-2" [ngClass]="{'blink': isDocumentPending(document.status) }"></span>
                                        <i-feather [name]="getNameIconDocument(document)" class="icon-tables2"></i-feather> <b> {{ document.document_type_name }}</b> {{ document.document_type_description }}
                                    </div>
                                    <div class="col-2" [align]="document.hash && document.hash !== '' && document.status === 'signed' ? 'center' : 'end'">
                                        <span class="badge rounded-pill spacing status-text" [ngClass]="getClassStatusTitle(document)">
                                            {{ translateStatusTitle(document) }}
                                        </span>
                                    </div>
                                    <div class="col-2 d-flex justify-content-end" *ngIf="document.hash && document.hash !== '' && document.status === 'signed'">
                                        <a *ngIf="!document.contractDownloaded" (click)="download(document)" class="ms-2 buttonss button5 cursor-pointer" style="position: relative;" [title]="document.contractError ? 'Error al descargar el documento. Inténtelo más tarde' : 'Descargar documento'">
                                            <i-feather name="download" class="icon-tables"></i-feather>
                                            <span *ngIf="document.contractError" class="dot-cancel-red icon-error"></span>
                                        </a>
                                        <span *ngIf="document.contractDownloaded" class="spinner-grow ms-2" role="status" aria-hidden="true" style="display: inline-block; width: 32px; bottom: -8px; position: relative;"></span>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="mt-4 mb-2">
                                <button (click)="openModalSend()" [disabled]="confirmSkipKYC" class="btn btn-simyo-o2 btn-sm p-2">
                                    Enviar contrato para firmar
                                </button>

                                <button *ngIf="environment.production == false && checkIfIsEsimOrder() && pdv?.info?.username === 'demo_pdv'" [disabled]="confirmSkipKYC" type="button" class="btn btn-simyo-o2 btn-sm p-2 mx-2" (click)="skypKYC()">
                                    Saltar Documentación
                                </button>
                            </div>
                        </div>
                        <!-- MANUAL -->
                        <div class="mt-3 total" *ngIf="checkTypeSignSelected('manual')">
                            <ng-container *ngFor="let document of orderDocuments; let i = index">
                                <div class="d-flex justify-content-between align-items-center px-2 py-3" style="border: .5px solid #f1f1f1;">
                                    <div class="col-8">
                                        <span class="dot-tables me-2" [ngClass]="{'blink': isDocumentPending(document.status) }"></span>
                                        <i-feather [name]="getNameIconDocument(document)" class="icon-tables2"></i-feather> <b> {{ document.document_type_name }}</b> {{ document.document_type_description }}
                                    </div>
                                    <div class="col-2 d-flex justify-content-center" >
                                        <span class="badge rounded-pill spacing status-text" [ngClass]="getClassStatusTitle(document)">
                                            {{ translateStatusTitle(document) }}
                                        </span>
                                    </div>
                                    <div class="col-2 d-flex justify-content-end" *ngIf="!document.removing">
                                        <span *ngIf="document.hash && document.hash !== '' && (document.document_type_code === 'document' || document.document_type_code === 'contract') && document.document_upload_type !== 'auto'" aria-hidden="true" class="me-2 cursor-pointer" title="Eliminar documento" (click)="deleteDoc(document)">
                                            <img src="./assets/img/trash.svg" class="img-fluid ico-title" width="17">
                                        </span>
                                        <a *ngIf="!(document.document_type_code === 'contract' && document.needs_to_be_downloaded) && !(document.document_type_code === 'document' && document.merged_images >= NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT)" 
                                            (click)="handleOpenDocumentationModal(document)" 
                                            class="buttonss button5 cursor-pointer" 
                                            title="Subir documento">
                                            <i-feather name="upload" class="icon-tables"></i-feather>
                                         </a>
                                        <ng-container *ngIf="(document.hash && document.hash !== '' && !document.needs_to_be_downloaded) || document.document_type_code === 'contract'">
                                            <a *ngIf="!document.contractDownloaded" (click)="download(document)" class="ms-2 buttonss button5 cursor-pointer" style="position: relative;" [title]="document.contractError ? 'Error al descargar el documento. Inténtelo más tarde' : 'Descargar documento'">
                                                <i-feather name="printer" class="icon-tables"></i-feather>
                                                <span *ngIf="document.contractError" class="dot-cancel-red icon-error"></span>
                                            </a>
                                            <span *ngIf="document.contractDownloaded" class="spinner-grow ms-2" role="status" aria-hidden="true" style="display: inline-block; width: 32px; bottom: -8px; position: relative;"></span>
                                        </ng-container>
                                    </div>
                                    <div class="col-2 d-flex justify-content-end" *ngIf="document.removing">
                                        <span class="spinner-grow ms-2" role="status" aria-hidden="true" style="display: inline-block; width: 32px; bottom: -8px; position: relative;"></span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="allUploadedDocumentsAreSignedOrValidationPending()" >
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="subtitulo-pack2">
                                    <b>Valida la documentación</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body bg-white">
                        <!-- Check de confirmacion -->
                        <div class="checkbox icheck-primary border-simyo pt-2 ms-3" [formGroup]="signatureFormGroup">
                            <input type="checkbox" id="documentationUploadCheck" class="accept-check" formControlName="documentationUploadCheck" (change)="changeCheckDocumentsAreCorrect($event.currentTarget.checked)"/>
                            <label for="documentationUploadCheck">
                                <b>Confirmo que he revisado la documentación y es correcta</b>
                            </label>
                            <mat-error *ngIf="errorHandling('documentationUploadCheck', 'requiredTrue')">
                                <small class="ps-4 ms-1">Campo obligatorio.</small>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Visualizar documentación -->
    <ng-container *ngIf="documentationMode === documentationTypes.viewDocumentation">
        <div class="row">
            <div class="col-xl-12 mb-3">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="subtitulo-pack2">
                                    <b>Documentación</b>
                                    <ng-container *ngIf="fullOrder.doc_annotation && fullOrder.doc_annotation !== ''">
                                        <br>
                                        <span style="font-size: 14px;">Comentario: {{ fullOrder.doc_annotation }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="getLastUploadFileDate() !== ''">
                                        <br>
                                        <span style="font-size: 14px;">Últ. subida {{ getLastUploadFileDate() }}</span>
                                    </ng-container>
                                </h6>
                            </div>
                            <small class="text-muted cursor-pointer" title="{{ fullOrder.doc_annotation && fullOrder.doc_annotation !== '' ? fullOrder.doc_annotation : '' }}">
                                <div class="checkmark{{ fullOrder.doc_status && fullOrder.doc_status === simyoDocStatus.ok ? '' : '-disabled' }}"></div>
                            </small>
                        </div>
                    </div>

                    <div class="card-body bg-white">
                        <div class="row" *ngIf="!fullOrder.is_contingency && !hideContractInfo">
                            <div class="col-xl-12 mb-3">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h6 class="subtitulo-pack2">
                                                    <b>Documentación aportada durante la contratación</b>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body bg-white">
                                        <ng-container *ngFor="let document of orderDocuments; let i = index">
                                            <div *ngIf="document.document_type_code !== 'other'" class="d-flex justify-content-between align-items-center ms-2 my-2">
                                                <div>
                                                    <div>
                                                        <span class="{{ convertStatusDocumentClass(document) }} me-2"></span>
                                                        <i-feather [name]="getNameIconDocument(document)" class="icon-tables2"></i-feather> <b> {{ document.document_type_name }}</b> {{ document.document_type_description }}
                                                    </div>
                                                    <div *ngIf="document.annotation && document.annotation !== ''">
                                                        <span style="color: #212529; font-size: 14px;">
                                                            Comentario: {{ document.annotation }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <a *ngIf="!document.contractDownloaded && !(fullOrder.is_contingency && document.hash == null)" (click)="download(document)" class="buttonss button5 cursor-pointer" style="position: relative;" [title]="document.contractError ? 'Error al descargar el documento. Inténtelo más tarde' : 'Descargar documento'">
                                                    <i-feather name="download" class="icon-tables"></i-feather>
                                                    <span *ngIf="document.contractError" class="dot-cancel-red icon-error"></span>
                                                </a>
                                                <span *ngIf="document.contractDownloaded" class="spinner-grow" role="status" aria-hidden="true" style="display: inline-block; width: 32px; position: relative;"></span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- Contrato sin firmar -->
                                <div *ngIf="!this.permSv.hasPermFromV2(allPermissions.simyo.tuvo_activaciones) && (fullOrder?.super_status === superStatus.in_process || fullOrder?.super_status === superStatus.active)" class="card-footer" style="border:none">
                                    <div *ngIf="{ document_type_code: 'contract' } as doc" class="d-flex justify-content-between align-items-center ms-2">
                                        <div class="cursor-pointer" (click)="downloadContractWithoutSing(doc)">
                                            <b> Contrato sin firmar</b>
                                            <i-feather name="download" class="icon-tables ms-2"></i-feather>
                                        </div>
                                        <span *ngIf="doc.contractDownloaded" class="spinner-grow" role="status" aria-hidden="true" style="display: inline-block; width: 32px; position: relative;"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="fullOrder?.precontract?.createdAt" class="card-footer" style="border:none">
                            <div *ngIf="{ document_type_code: 'contract' } as predoc" class="d-flex justify-content-between align-items-center ms-2">
                                <div class="cursor-pointer" (click)="downloadPreContract()">
                                    <b> Resumen Contrato</b>
                                    <i-feather name="download" class="icon-tables ms-2"></i-feather>
                                </div>
                                <span *ngIf="loadingPreDownload" class="spinner-grow" role="status" aria-hidden="true" style="display: inline-block; width: 32px; position: relative;"></span>
                            </div>
                        </div>

                        <div class="row" *ngIf="!this.permSv.hasPermFromV2(allPermissions.simyo.tuvo_activaciones) && (fullOrder?.super_status === superStatus.in_process || fullOrder?.super_status === superStatus.active) && !hideContractInfo">
                            <div class="col-xl-12 mt-2">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h6 class="subtitulo-pack2">
                                                    <b>Documentación adicional</b>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body bg-white">
                                        <p>Adjunto aquí documentación a actualizar o pendiente</p>
                                        <ng-container *ngFor="let document of orderDocuments; let i = index">
                                            <div *ngIf="document.document_type_code === 'other'" class="d-flex justify-content-between align-items-center ms-2 my-2">
                                                <div>
                                                    <div>
                                                        <span class="{{ convertStatusDocumentClass(document) }} me-2"></span>
                                                        <i-feather [name]="getNameIconDocument(document)" class="icon-tables2"></i-feather> <b> {{ document.document_type_name }}</b> {{ document.document_type_description }}
                                                    </div>
                                                    <div *ngIf="document.annotation && document.annotation !== ''">
                                                        <span style="color: #212529; font-size: 14px;">
                                                            Comentario: {{ document.annotation }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <a *ngIf="!document.contractDownloaded" (click)="download(document)" class="buttonss button5 cursor-pointer" style="position: relative;" [title]="document.contractError ? 'Error al descargar el documento. Inténtelo más tarde' : 'Descargar documento'">
                                                    <i-feather name="download" class="icon-tables"></i-feather>
                                                    <span *ngIf="document.contractError" class="dot-cancel-red icon-error"></span>
                                                </a>
                                                <span *ngIf="document.contractDownloaded" class="spinner-grow" role="status" aria-hidden="true" style="display: inline-block; width: 32px; position: relative;"></span>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="!nextMonthDay5" class="mt-4 mb-2">
                                            <a href="#!" data-bs-toggle="modal" data-bs-target="#documentationModal" class="btn btn-simyo-o2 btn-sm p-2">
                                                Subir documentación
                                            </a>
                                        </div>
                                        <div *ngIf="nextMonthDay5" class="mt-4 mb-2" style="display: flex">
                                                <a class="btn btn-simyo-o2 disabled btn-sm p-2">
                                                    Subir documentación
                                                </a>
                                                <mat-error class="pt-2 ms-3">
                                                    Fuera de plazo para subida de documentación
                                                </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!-- No existen datos-->
<ng-container *ngIf="!orderDocumentsError && orderDocuments && orderDocuments.length === 0" >
    <div class="col-xl-12 mt-2 px-0">
        <div class="card mb-3">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <h6 class="subtitulo-pack2">
                            <b>Documentación</b>
                        </h6>
                    </div>
                    <small class="text-muted cursor-pointer">
                        <div class="checkmark"></div>
                    </small>
                </div>
            </div>
            <div class="card-body bg-white" *ngIf="{ document_type_code: 'contract' } as doc">

                <div class="row" *ngIf="!hideContractInfo">
                    <div class="col-xl-12 mb-3">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2">
                                            <b>Documentación de la contratación</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">
                                <ng-container>
                                    <div class="d-flex justify-content-between align-items-center ms-2 my-2">
                                        <div>
                                            <div>
                                                <span>
                                                    No es necesario que subas documentación para este pedido
                                                </span>
                                            </div>
                                            <div>
                                                <i-feather name="file" class="icon-tables2"></i-feather> <b> Contrato</b> servicio Fibra y Móvil en Simyo
                                            </div>
                                        </div>
                                        <a (click)="download(doc)" *ngIf="!doc.contractDownloaded"  class="buttonss button5 cursor-pointer" style="position: relative;" [title]="doc.contractError ? 'Error al descargar el documento. Inténtelo más tarde' : 'Descargar documento'">
                                            <i-feather name="download" class="icon-tables"></i-feather>
                                            <span *ngIf="doc.contractError" class="dot-cancel-red icon-error"></span>
                                        </a>
                                        <span *ngIf="doc.contractDownloaded" class="spinner-grow" role="status" aria-hidden="true" style="display: inline-block; width: 32px; position: relative;"></span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="fullOrder?.precontract?.createdAt" class="card-footer" style="border:none">
                    <div *ngIf="{ document_type_code: 'contract' } as predoc" class="d-flex justify-content-between align-items-center ms-2">
                        <div class="cursor-pointer" (click)="downloadPreContract()">
                            <b> Resumen Contrato</b>
                            <i-feather name="download" class="icon-tables ms-2"></i-feather>
                        </div>
                        <span *ngIf="loadingPreDownload" class="spinner-grow" role="status" aria-hidden="true" style="display: inline-block; width: 32px; position: relative;"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Error al obtener los datos -->
<ng-container *ngIf="orderDocumentsError" >
    <div class="col-xl-12 mt-2 px-0">
        <div class="card mb-3">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <h6 class="subtitulo-pack2">
                            <b>Documentación</b>
                        </h6>
                    </div>
                </div>
            </div>
            <div class="card-body bg-white">
                Error al obtener los documentos a firmar
            </div>
        </div>
    </div>
</ng-container>

<!-- Loading -->
<div *ngIf="!orderDocuments && !orderDocumentsError" class="my-5 d-flex justify-content-center" >
    <img src="./assets/img/spinner-simyo.svg" class="img-fluid" width="30">
</div>

<!-- Modal documentación -->
<div class="modal fade" id="documentationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" #documentationModal>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-simyo-grey">
                <h5 class="modal-title white" id="exampleModalLabel">
                    Subir documento
                </h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" #closeDocumentationModal>
                    <span aria-hidden="true">
                        <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12">
                        <h6 *ngIf="selectDocToUpload?.file_name && selectDocToUpload.file_name !== '' && !uploadingFile" class="mb-3 font-subida text-muted fw-bold">
                            Título del documento subido: {{ selectDocToUpload.file_name }}
                        </h6>
                        <div class="dropzone" id="dropZone"
                             (drop)="dropFile($event)"
                             (dragover)="allowDrop($event)"
                             (click)="errorDuplicateFileUpload=false; inputFile.click()">
                            <div *ngIf="!uploadingFile" class="dropzone-container">
                                <div class="file-icon">+</div>
                                <div class="dropzone-title" *ngIf="!selectDocToUpload?.file_name || selectDocToUpload?.file_name === ''">
                                    Adjunta pinchando <span class="cursor-pointer browse">aquí</span> el documento correspondiente
                                </div>
                                <div class="dropzone-title" *ngIf="selectDocToUpload?.file_name && selectDocToUpload?.file_name !== ''">
                                    <!-- Documento y se pueden subir más ficheros -->
                                    <ng-container *ngIf="(selectDocToUpload?.document_type_code === 'document' && selectDocToUpload?.merged_images < NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT) || selectDocToUpload?.document_type_code === 'contract'">
                                        Añada otro archivo pinchando <span class="cursor-pointer browse">aquí</span>
                                    </ng-container>
                                    <ng-container *ngIf="selectDocToUpload?.document_type_code !== 'document' && selectDocToUpload?.document_type_code !== 'contract'">
                                        Sustituya el documento pinchando <span class="cursor-pointer browse">aquí</span>
                                    </ng-container>
                                </div>
                            </div>
                            <img *ngIf="uploadingFile"
                                 src="./assets/img/spinner-simyo.svg"
                                 class="img-fluid"
                                 width="30">
                            <input id="fileBtn" name="files" type="file" onclick="this.value=null" (change)="fileChanged($event)" class="file-input" #inputFile [multiple]="selectDocToUpload?.document_type_code === 'document' || selectDocToUpload?.document_type_code === 'contract'"/>
                        </div>
                        <mat-error class="pt-2" *ngIf="errorNumberFileUpload">
                            No se pueden subir más de {{ NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT }} ficheros
                        </mat-error>
                        <mat-error class="pt-2" *ngIf="errorDuplicateFileUpload">
                            No se puede subir dos veces el mismo documento
                        </mat-error>
                        <mat-error class="pt-2" *ngIf="showErrorUploadFile && showErrorUploadFile !== ''">
                            {{ showErrorUploadFile || 'Ha ocurrido un error al subir el documento. Por favor, inténtelo de nuevo más tarde.'}}
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal petición -->
<div class="modal fade" id="peticion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-simyo-grey">
                <h5 class="modal-title white" id="exampleModalLabel">Petición electrónica</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" #closeSendLinkModal *ngIf="!loadingSignDocuments">
                    <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="!loadingSignDocuments && !signDocumentsComplete">
                    <div class="col-xl-12" *ngIf="errorSignDocuments">
                        <div class="alert alert-danger mt-0" role="alert">
                            <h5 class="alert-heading mt-2"><b>Error</b></h5>
                            <p>{{messageErrorSignDocuments}}</p>
                        </div>
                    </div>
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-header">
                                <b> Has seleccionado <span class="badge bg-dark text-white">{{ orderDocuments?.length }}</span> {{ orderDocuments?.length === 1 ? 'petición electrónica' : 'peticiones electrónicas' }}:</b>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-xl">
                                        <b class="text-orange">
                                            <ng-container *ngFor="let docum of orderDocuments; last as isLast; first as isFirst">
                                                {{ !isFirst && isLast ? ' y ' : '' }}{{ !isFirst && !isLast ? ', ' : '' }}{{ docum.document_type_abbr }}
                                            </ng-container>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <h6 *ngIf="isPortabilityTitularChange" class="modal-title text-muted mb-2 fw-bold spacing" id="exampleModalLabel">Método de envío del contrato de cambio de titularidad</h6>
                        <table *ngIf="isPortabilityTitularChange" class="table table-sm table-bordered">
                            <tbody [formGroup]="signatureFormGroup">
                            <tr>
                                <td>
                                    <div class="ml-2 area-check area-radio">
                                        <div class="checkbox icheck-primary">
                                            <input type="checkbox" id="smsCheckChangeOwner" formControlName="smsCheckChangeOwner" disabled/>
                                            <label for="smsCheckChangeOwner">SMS</label>
                                        </div>
                                    </div>
                                </td>
                                <td class="pt-2 ps-3 pe-3">
                                    <div class="d-flex justify-content-between align-items-start w-100 gap-3">
                                      <div style="flex: 1;">
                                        <digo-input
                                          id="smsDataDonor"
                                          [label]="'Nº de contacto donante'"
                                          [formGroup]="signatureFormGroup"
                                          controlName="smsDataDonor"
                                          type="text"
                                          [isDisabled]="true"
                                        />
                                      </div>
                                      <div style="flex: 1;">
                                        <digo-input
                                          id="smsDataNewOwner"
                                          [label]="'Nº de contacto titular nuevo'"
                                          [formGroup]="signatureFormGroup"
                                          controlName="smsDataNewOwner"
                                          type="text"
                                          [isDisabled]="true"
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  
                            </tr>
                            </tbody>
                        </table>
                        <h6 class="modal-title text-muted mb-2 fw-bold spacing" id="exampleModalLabel">Método de envío <ng-container *ngIf="isPortabilityTitularChange">del contrato de alta en Simyo</ng-container></h6>
                        <table class="table table-sm table-bordered">
                            <tbody [formGroup]="signatureFormGroup">
                            <tr>
                                <td>
                                    <div class="ms-2 area-check area-radio">
                                        <div class="checkbox icheck-primary">
                                            <input type="checkbox" id="smsCheck" formControlName="smsCheck" (change)="formsDataIsRequired('smsCheck')"/>
                                            <label for="smsCheck">SMS</label>
                                        </div>
                                    </div>
                                </td>
                                <td class="ps-3 pe-3 pt-2">
                                    <digo-select
                                        id="status"
                                        label="Nº de contacto"
                                        [formGroup]="signatureFormGroup"
                                        controlName="smsData"
                                        [options]="phoneOptionsFormatted"
                                        optionValue="phone"
                                        optionLabel="label"
                                        [isRequired]="isControlRequired('smsData')"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="ms-2 area-check area-radio">
                                        <div class="checkbox icheck-primary">
                                            <input appBlockPaste type="checkbox" id="emailCheck" formControlName="emailCheck" (change)="formsDataIsRequired('emailCheck')"/>
                                            <label for="emailCheck">Correo electrónico</label>
                                        </div>
                                    </div>
                                </td>
                                <td class="ps-3 pe-3 pt-2">
                                    <digo-input
                                        id="email"
                                        [label]="'Correo electrónico'"
                                        [formGroup]="signatureFormGroup"
                                        controlName="emailData"
                                        type="text"
                                        [isRequired]="isControlRequired('emailData')"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="mt-4 mb-3 d-flex justify-content-center">
                            <a class="btn btn-simyo mt-1 mb-1" (click)="sendLink()" role="button">
                                Enviar link petición
                            </a>
                        </div>
                    </div>
                </div>

                <div class="my-5 d-flex justify-content-center align-items-center gap-3" *ngIf="loadingSignDocuments">
                    <h5 class="tar-name-preload mb-0" style="font-size: 16px !important;">
                      Enviando documentación para firmar...
                    </h5>
                    <img src="./assets/img/spinner-simyo.svg" width="30" height="30" alt="Cargando..." />
                </div>
                  
                <div class="row" *ngIf="signDocumentsComplete && !loadingSignDocuments">
                    <div class="col-xl-12">
                        <div class="mx-5 mb-4 mt-3 text-center">
                            <div class="d-flex justify-content-center mb-3">
                              <img src="./assets/img/ok-blue.svg" width="60" alt="OK icon" />
                            </div>
                          
                            <ng-container *ngIf="signatureFormGroup.value as signatureValues">
                              <ng-container *ngIf="!isPortabilityTitularChange">
                                <p class="textJustified">
                                  Ya se ha enviado correctamente
                                  {{ signatureValues.smsCheck ? ' un sms al teléfono ' : '' }} <b>{{ signatureValues.smsCheck ? signatureValues.smsData : '' }}</b>
                                  {{ signatureValues.smsCheck && signatureValues.emailCheck ? ' y ' : '' }}
                                  {{ signatureValues.emailCheck ? ' un email a ' : '' }} <b>{{ signatureValues.emailCheck ? signatureFormGroup.get('emailData').value : '' }}</b>
                                  para que pueda firmar la documentación.
                                </p>
                              </ng-container>
                          
                              <ng-container *ngIf="isPortabilityTitularChange">
                                <p *ngIf="!signDocumentsDonorComplete" class="textJustified">
                                  Ya se ha enviado correctamente un SMS al antiguo titular <b>({{signatureValues.smsDataDonor}})</b> y posteriormente, se enviará al nuevo titular <b>({{signatureValues.smsDataNewOwner}})</b>
                                  para que puedan realizar la firma de la documentación de la portabilidad.
                                </p>
                          
                                <p *ngIf="!signDocumentsDonorComplete" class="textJustified">
                                  Una vez firmados los documentos, se enviará
                                  {{ signatureValues.smsCheck ? ' un sms al teléfono ' : '' }} <b>{{ signatureValues.smsCheck ? signatureValues.smsData : '' }}</b>
                                  {{ signatureValues.smsCheck && signatureValues.emailCheck ? ' y ' : '' }}
                                  {{ signatureValues.emailCheck ? ' un email a ' : '' }} <b>{{ signatureValues.emailCheck ? signatureFormGroup.get('emailData').value : '' }}</b>
                                  para que pueda firmar la documentación del contrato.
                                </p>
                          
                                <p *ngIf="signDocumentsDonorComplete" class="textJustified">
                                  Ya se ha firmado la solicitud de portabilidad por el titular donante y el nuevo titular.
                                </p>
                          
                                <p *ngIf="signDocumentsDonorComplete" class="textJustified">
                                  Se ha enviado
                                  {{ signatureValues.smsCheck ? ' un sms al teléfono ' : '' }} <b>{{ signatureValues.smsCheck ? signatureValues.smsData : '' }}</b>
                                  {{ signatureValues.smsCheck && signatureValues.emailCheck ? ' y ' : '' }}
                                  {{ signatureValues.emailCheck ? ' un email a ' : '' }} <b>{{ signatureValues.emailCheck ? signatureFormGroup.get('emailData').value : '' }}</b>
                                  para que pueda firmar la documentación del contrato.
                                </p>
                              </ng-container>
                            </ng-container>
                          
                            <p class="textJustified">
                              En cuanto haya realizado la firma de los documentos se actualizará en el detalle del pedido.
                            </p>
                          </div>
                          
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
